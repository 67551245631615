import React, { useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Divider } from "@adobe/react-spectrum";
import PluginsLayout from "../../components/PluginsLayout";
import { PluginContext } from "../../contexts/PluginContext";
import PluginInfoCard from "../../components/PluginInfoCard";
import ThemePanelSVG from "../../components/ThemePanelSVG";
import SEO from "../../components/seo";
import SchemaORG from "../../data/theme-schema.json";
import pluginsArray from "../../data/plugins.json"

const WellContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */
  /* position: relative; */
  width: 100%;
  text-align: center;

  .plugin-banner {
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  @media (min-width: 900px) {
    font-size: var(--spectrum-global-dimension-font-size-400) !important;
    .banner-well {
      box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.2),
        0 1px 0 rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      max-width: 900px;
      margin: 0 auto;
      background-color: var(--spectrum-global-color-gray-75);
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }
  }
`;

const ThemePlugin = () => {
  const { ThemeBanner, ThemeCarousel } = useStaticQuery(
    graphql`
      query {
        ThemeBanner: file(
          relativePath: { eq: "highlights/theme-highlight.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        ThemeCarousel: file(
          relativePath: { eq: "theme-carousel/theme-switcher.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  );

  const { activePlugin, setActivePlugin } = useContext(PluginContext);
  useEffect(() => {
    if (activePlugin !== "theme-switcher") {
      setActivePlugin("theme-switcher");
    }
  });

  const pluginInfoCardSettings = {
    logo: "Theme-SVG",
    logoColor: "var(--spectrum-global-color-blue-400)",
    title: "Theme Switcher",
    version: pluginsArray.filter(plugin => plugin.title === "Theme Switcher")[0].version,
    price: undefined,
    link:
      "https://adobe.com/go/cc_plugins_discover_plugin?pluginId=d1b6f323&workflow=share",
  };

  return (
    <PluginsLayout>
      <SEO
        title="Theme Switcher for Photoshop"
        description="Theme Switcher, Photoshop UI Themes Manager plugin"
        image="https://www.cc-extensions.com/img/twitter-theme.jpg"
        slug="plugins/theme-switcher"
        schema={SchemaORG}
      />
      <WellContainer>
        <div className="banner-well">
          <h1 className="plugin">Theme Switcher</h1>
          <h2
            className="plugin"
            style={{ color: `var(--spectrum-global-color-blue-400)` }}
          >
            Photoshop UI Themes Manager
          </h2>
          <Img
            fluid={ThemeBanner.childImageSharp.fluid}
            className="plugin-banner"
          />
        </div>
      </WellContainer>

      <div style={{ textAlign: `left`, maxWidth: `900px` }}>
        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          In short
        </h3>
        <Divider size="M" />
        <p>
          Quickly select Photoshop UI Themes in a breeze! Click one of the four
          buttons to change the entire user inteface: no need to dig through the
          Preferences anymore.
        </p>
        <Img
          fluid={ThemeCarousel.childImageSharp.fluid}
          style={{ borderRadius: `6px`, marginBottom: `50px` }}
        />
        <ThemePanelSVG />

        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          Get it
        </h3>
        <Divider size="M" />
        <p>
          It&rsquo;s free, it&rsquo;s tiny, it&rsquo;s useful. What not to like.
        </p>
        <PluginInfoCard
          style={{ margin: `50px auto` }}
          pluginInfo={pluginInfoCardSettings}
        />
      </div>
    </PluginsLayout>
  );
};
export default ThemePlugin;
