import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useContext } from "react";
import DusmPanel from "../icons/plugins/theme/theme-panel.svg";
import { ThemeContext } from "../contexts/ThemeContext";

const DusmPanelSVG = () => {
  const { ThemePanelDarkPNG, ThemePanelLightPNG } = useStaticQuery(
    graphql`
      query {
        ThemePanelDarkPNG: file(
          relativePath: { eq: "theme-panel/theme-panel-darktheme.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 900, toFormat: PNG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ThemePanelLightPNG: file(
          relativePath: { eq: "theme-panel/theme-panel-lighttheme.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 900, toFormat: PNG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  const { colorScheme } = useContext(ThemeContext);
  return (
    <div style={{ position: `relative` }}>
      <Img
        fluid={
          colorScheme === "dark"
            ? ThemePanelDarkPNG.childImageSharp.fluid
            : ThemePanelLightPNG.childImageSharp.fluid
        }
      />
      <DusmPanel style={{ position: `absolute`, top: `0`, left: `0` }} />
    </div>
  );
};

export default DusmPanelSVG;
